import { router, usePage } from "@inertiajs/vue3";
import { useReCaptcha } from "vue-recaptcha-v3";
import Noty from "noty";
import { PaymentType, ProductType, SocialitePlatform } from "@Res/js/enums";
import categories from "@Storage/categories.json";
import countries from "@Storage/countries.json";
import currencies from "@Storage/currencies.json";
import languages from "@Storage/languages.json";
import BundleImg from "@Images/bundle.webp";
import CourseImg from "@Images/course.webp";
import DigitalDownloadImg from "@Images/download.webp";
import MembershipImg from "@Images/membership.webp";
// import ziggyRoute from 'ziggy-js'

// export const route = (name, params, absolute, config = usePage().props.ziggy) => ziggyRoute(name, params, absolute, config)

export function useCaptcha() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    return {
        execute: async (action = "") => {
            if (usePage().props.captchaKey === null) return;

            await recaptchaLoaded();

            return await executeRecaptcha(action);
        },
    };
}

export const uid = () =>
    Date.now().toString(36) + Math.random().toString(36).substring(2);

export const randomString = (length = 10) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
};

export const slugify = (str) => {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    let from =
        "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
    let to =
        "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";

    for (let i = 0; i < from.length; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    return str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes
};

/**
 * Delay or Wait for some seconds.
 *
 * @param seconds The number of seconds to delay
 * @return Promise
 */
export const sleep = (seconds) =>
    new Promise((r) => setTimeout(r, Number(seconds) * 1000));

export const notify = ({ text, type, timeout = 6000 }) => {
    Noty.closeAll(); // Remove all active noty

    new Noty({
        type: type,
        timeout: timeout,
        text: text,
        theme: "sunset",
        closeWith: ["click", "button"],
        progressBar: false,
        // layout: "topCenter",
        animation: {
            open: "animate-noty-in",
            close: "animate-noty-out",
        },
    }).show();
};

export const socialite = (driver) => {
    if (!Object.values(SocialitePlatform).includes(driver)) {
        notify({ text: "Invalid driver selected", type: "error" });
    } else {
        location.href = route("socialite", driver);
    }
};

const parseAndGetCurrentUserCountry = (currencyCode) => {
    let country = getCountry(usePage().props.user?.country);

    currencyCode =
        currencyCode === null ? country?.currency?.code : currencyCode;
    currencyCode = currencyCode === null ? "NGN" : currencyCode;

    return country === null ? getCountryByCurrencyCode(currencyCode) : country;
};

export const moveArrayValueToPosition = (arr, cb, position) => {
    const index = arr.findIndex(cb);

    if (index === -1) {
        throw new Error(`Object with "${index}" not found in array`);
    }

    if (position < 0 || position >= arr.length) {
        throw new Error(`Invalid position "${position}"`);
    }

    const objToMove = arr[index];

    arr.splice(index, 1);

    arr.splice(position, 0, objToMove);

    return arr.slice();
};

export const formatForDate = (
    dateString,
    countryCode = null,
    options = null
) => {
    const country = getCountry(countryCode ?? usePage().props.user?.country);
    const date = new Date(dateString);

    return date.toLocaleString(
        country?.locales[0] ?? undefined,
        isNullOrEmpty(options)
            ? {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
            }
            : options
    );
};

export const formatForMoney = (number, currencyCode = null) => {
    const country = parseAndGetCurrentUserCountry(currencyCode);

    return new Intl.NumberFormat(country?.locales[0] ?? undefined, {
        currency: country?.currency?.code ?? currencyCode,
        style: "currency",
    })
        .format(number)
        .replace(/^(\D+)/, "$1 ")
        .replace(/\s+/, " ");
};

// export const formatForSize = (bytes, precision = 2) => {
//     if (!+bytes) return '0 Bytes'
//
//     const base = 1024
//     const dm = precision < 0 ? 0 : precision
//     const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
//
//     const i = Math.floor(Math.log(bytes) / Math.log(base))
//
//     return `${parseFloat((bytes / Math.pow(base, i)).toFixed(dm))} ${units[i]}`
// }

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param precision Number of decimal places to display.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 *
 * @return Formatted string.
 */
export const formatForSize = (bytes, precision = 2, si = false) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) return bytes + " Bytes";

    const units = si
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** precision;

    do {
        bytes /= thresh;
        ++u;
    } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
    );

    return bytes.toFixed(precision) + " " + units[u];
};

export const currencySymbol = (currencyCode = null) => {
    const country = parseAndGetCurrentUserCountry(currencyCode);

    return Number()
        .toLocaleString(country?.locales[0] ?? undefined, {
            currency: country?.currency?.code ?? currencyCode,
            style: "currency",
        })
        .slice(0, 1);
};

export const getLevels = () => [
    {
        id: "beginner",
        name: "Beginner",
    },
    {
        id: "intermediate",
        name: "Intermediate",
    },
    {
        id: "expert",
        name: "Expert",
    },
];

export const getCategories = () => categories;

export const getCategory = (id) => {
    if (isNullOrEmpty(id)) return null;

    const categories = getCategories();
    const total = categories.length;
    let category;

    for (let i = 0; i < total; i++) {
        if (categories[i].id.toUpperCase() !== id.toUpperCase()) continue;

        category = categories[i];
        break;
    }

    return category;
};

export const getCountryFlag = async (code, size = '4x3') => {
    if (!['1x1', '4x3'].includes(size)) return;

    return (await import(`../../node_modules/flag-icons/flags/${size}/${code.toLowerCase()}.svg`)).default
}

export const getCountries = () => countries;

export const getPayoutCountryCodes = () => usePage().props.payoutCountryCodes;

export const getSupportedCountryCodes = () => usePage().props.supportedCountryCodes;

export const getPayoutCountries = () => {
    const supported = getPayoutCountryCodes();
    const countries = getCountries();
    const supportedCountries = countries.filter(country => supported.includes(country.iso2.toUpperCase()));

    return supportedCountries.sort((a, b) => supported.indexOf(a.iso2.toUpperCase()) - supported.indexOf(b.iso2.toUpperCase()));
};

export const getSupportedCountries = () => {
    const supported = getSupportedCountryCodes();
    const countries = getCountries();
    const supportedCountries = countries.filter(country => supported.includes(country.iso2.toUpperCase()));

    return supportedCountries.sort((a, b) => supported.indexOf(a.iso2.toUpperCase()) - supported.indexOf(b.iso2.toUpperCase()));
};

export const getCountry = (code) => {
    if (isNullOrEmpty(code)) return null;

    const countries = getCountries();
    const total = countries.length;
    let country;

    for (let i = 0; i < total; i++) {
        if (
            countries[i].iso2.toUpperCase() !== code.toUpperCase() &&
            countries[i].iso3.toUpperCase() !== code.toUpperCase()
        )
            continue;

        country = countries[i];
        break;
    }

    return country;
};

export const getCountryByCurrencyCode = (code) => {
    if (isNullOrEmpty(code)) return null;

    const countries = getCountries();
    const total = countries.length;
    let country;

    for (let i = 0; i < total; i++) {
        if (countries[i].currency.code.toUpperCase() !== code.toUpperCase())
            continue;

        country = countries[i];
        break;
    }

    return country;
};

export const getCountryName = (code) => getCountry(code)?.name;

export const getCurrencies = () => currencies;

export const getSupportedCurrencyCodes = () => [...new Set(getSupportedCountries().map(country => country.currency.code))];
export const getPayoutCurrencyCodes = () => [...new Set(getPayoutCountries().map(country => country.currency.code))];

export const getCurrency = (code) => {
    const currencies = getCurrencies();
    const total = currencies.length;
    let currency;

    for (let i = 0; i < total; i++) {
        if (currencies[i].code.toUpperCase() !== code.toUpperCase()) continue;

        currency = currencies[i];
        break;
    }

    return currency;
};

export const getLanguages = () => languages;

export const getLanguage = (code) => {
    const languages = getLanguages();
    const total = languages.length;
    let language;

    for (let i = 0; i < total; i++) {
        if (languages[i].code.toUpperCase() !== code.toUpperCase()) continue;

        language = languages[i];
        break;
    }

    return language;
};

export const mapObject = (obj, fn) => {
    let newObj = [];
    let i = 0;

    for (let [k, v] of Object.entries(obj)) {
        newObj[i] = fn(v, k);
        i++;
    }

    return newObj;
};

const fallbackCopyTextToClipboard = (text) => {
    let textArea = document.createElement("textarea");

    // Place in the top-left corner of screen regardless of scroll position.
    textArea.style.position = "fixed";
    textArea.style.top = "0";
    textArea.style.left = "0";

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = "2em";
    textArea.style.height = "2em";

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = "0";

    // Clean up any borders.
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";

    // Avoid flash of the white box if rendered for any reason.
    textArea.style.background = "transparent";

    textArea.value = text;

    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    let copied;

    try {
        copied = document.execCommand("copy");

        if (!copied) console.log("Failed to copy text");
    } catch (err) {
        console.log("Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
};

/**
 * Copies text to clipboard
 *
 * @see https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
 * @param text
 */
export const copyTextToClipboard = async (text) => {
    if (!navigator.clipboard) return fallbackCopyTextToClipboard(text);

    return await navigator.clipboard.writeText(text);
};

export const strSplit = (string, splitLength) => {
    if (splitLength === null) splitLength = 1;

    if (string === null || splitLength < 1) return false;

    string += "";

    const chunks = [];

    let pos = 0;

    const len = string.length;

    while (pos < len) chunks.push(string.slice(pos, (pos += splitLength)));

    return chunks;
};

export const getFileData = async (uuid) =>
    (await fetchUserUploadedFiles(uuid)) || {};

export const getImageUrl = async (uuid) => {
    const data = await getFileData(uuid);

    return data?.mimeType?.includes("image/") || false ? data.url : "";
};

export const storeUrl = (str = null) =>
    `${route("storefront.index", {
        store_slug: usePage()?.props.store.slug,
    })}/${isNullOrEmpty(str) ? "" : str}`;

export const isFunction = (value) =>
    value &&
    (Object.prototype.toString.call(value) === "[object Function]" ||
        "function" === typeof value ||
        value instanceof Function);

export const isUndefined = (value) => value === undefined;
export const isNullOrEmpty = (value) =>
    isUndefined(value) ||
    value === null ||
    value === "" ||
    (Array.isArray(value) && value.length === 0) ||
    (value === Object(value) && Object.keys(value).length === 0);
export const isNotUndefined = (value) => !isUndefined(value);
export const isNotNullOrEmpty = (value) => !isNullOrEmpty(value);

export const getFileSymbolLink = (mimeType = "unknown") => {
    mimeType ||= "unknown";

    const mimeTypes = {
        "application/zip": "#icon-zip-file",
        "application/x-zip-compressed": "#icon-zip-file",
        "application/pdf": "#icon-pdf-file",
        "application/msword": "#icon-doc-file",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            "#icon-docx-file",
        "text/plain": "#icon-txt-file",
        "video/": "#icon-video-file",
        "audio/": "#icon-audio-file",
        "image/": "#icon-image-file",
    };

    let symbolLink = "#icon-unknown-file";

    for (const mimeTypesKey in mimeTypes) {
        if (!mimeType.includes(mimeTypesKey)) continue;

        symbolLink = mimeTypes[mimeTypesKey];

        break;
    }

    return symbolLink;
};

export const getProductTypeSpillClassName = (type) => {
    switch (type) {
        default:
        case ProductType.DIGITAL_DOWNLOAD:
            return /*tw*/ {
                bg: "bg-violet-200 border border-violet-400",
                text: "text-violet-900",
                icon: "fill-violet-900",
            };
        case ProductType.BUNDLE:
            return /*tw*/ {
                bg: "bg-amber-200 border border-amber-400",
                text: "text-amber-900",
                icon: "fill-amber-900",
            };
        case ProductType.ONLINE_COURSE:
            return /*tw*/ {
                bg: "bg-pink-200 border border-pink-400",
                text: "text-pink-900",
                icon: "fill-pink-900",
            };
        case ProductType.MEMBERSHIP:
            return /*tw*/ {
                bg: "bg-green-200 border border-green-400",
                text: "text-green-900",
                icon: "fill-green-900",
            };
    }
};

export const getOrderTypeLabel = (type) => {
    switch (type) {
        case PaymentType.USSD:
            return "USSD";
        case PaymentType.CARD:
            return "Credit/Debit Card";
        case PaymentType.CHEQUE:
            return "Cheque";
        case PaymentType.PAYPAL:
            return "PayPal";
        case PaymentType.DIRECT_DEBIT:
            return "Bank Account/Direct Debit";
        case PaymentType.BANK_TRANSFER:
            return "Bank Transfer";
        default:
            return "N/A";
    }
};

export const getProductThumbnailUrl = (product) => {
    let defaultImage;

    switch (product.type) {
        case ProductType.BUNDLE:
            defaultImage = BundleImg;
            break;
        case ProductType.ONLINE_COURSE:
            defaultImage = CourseImg;
            break;
        case ProductType.MEMBERSHIP:
            defaultImage = MembershipImg;
            break;
        default:
            defaultImage = DigitalDownloadImg;
            break;
    }

    return isNullOrEmpty(product.thumbnailUrl)
        ? defaultImage
        : product.thumbnailUrl;
};

/**
 * Get user's storage used and limit
 *
 * @returns {Promise<object>}
 */
export const fetchUserStorageSense = async () => {
    const res = await fetch(route("user-storage-sense"));
    return await res.json();
};

/**
 * Get user's storage used and limit
 *
 * @returns {Promise<object>}
 */
export const fetchUserUploadedFiles = async (uuid = "") => {
    const res = await fetch(route("user-uploaded-files", uuid));
    return await res.json();
};

export const logout = async (cb = () => {
}) => {
    router.post(
        usePage().props.isStorefront
            ? route("storefront.logout", usePage().props.store.slug)
            : route("logout"),
        {},
        {
            onError: () => window.location.reload(),
            onFinish: async () => {
                try {
                    const response = await fetch(route("ziggy-routes"), {
                        headers: { Accept: "application/json" },
                    });
                    const json = await response.json();

                    Ziggy.routes = json.routes;
                    Ziggy.defaults = json.defaults;
                } catch (e) {
                }

                if (isFunction(cb)) cb();
            },
        }
    );
};

export const isInfinity = (v) => v === usePage().props.inf || v === Infinity;

export const className = (v) => v;

export const cx = (v) => v;
